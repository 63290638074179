/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Regular.woff2');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('/assets/fonts/Nunito-Bold.woff2');
  font-weight: bold;
  font-display: swap;
  font-style: normal;
}

.instructions img {
  width: 100%;
}

body {
  font-family: Nunito, 'Helvetica Neue', sans-serif;
}

.App {
  background-color: #f5f5f5;
  padding: 24px;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
}

.page {
  background: #fff;
  border: 1px solid #f0ddff;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 20px;
  margin: 10px auto;
}

@media (min-width: 1040px) {
  .page {
    width: 1040px;
    margin: 20px auto;
    padding: 40px;
  }
}

h1 {
  margin: 10px 0 0 0;
  font-size: 3em;
}

h2 {
  margin: 10px 0;
  font-size: 2em;
}

.emoji {
  margin-right: 10px;
}

.disclaimer {
  text-align: left;
  margin: 30px auto;
  padding-left: 15px;
  border-left: 5px solid #8f00ff;
  width: 650px;
  font-size: 1em;
  color: #BDBDBD;
}

.disclaimer strong {
  color: #8f00ff;
}

.disclaimer a {
  color: #BDBDBD;
  text-decoration: underline;
}

.logo {
  font-size: 2em;
  font-weight: bold;
}

.logo .dot{
  font-size: 1.25em;
  color: #8f00ff;
}

.footer {
  text-align:center;
  font-size: 0.75em;
  color: #BDBDBD;
  margin: 20px 0;
}

.footer a {
  color: #BDBDBD;
  text-decoration: underline;
}

.ant-tabs .ant-tabs-tab {
  padding: 8px 16px;
  margin: 0 16px 0 0;
}

.ant-btn-lg {
  height: auto;
  padding: 0.75em 1.25em;
}

.recharts-bar-rectangle {
  cursor: pointer;
}

.recharts-label {
  font-size: 0.8em;
}

button,
.ant-select {
  margin: 5px;
}

/* Dropzone */
.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  border-width: 3px;
  border-radius: 20px;
  border-color: #f0ddff;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: all 0.24s ease-in-out;
  margin-top: 20px;
  height: 250px;
  cursor: pointer;
}

.dropzone:hover {
  background-color: #fcf7ff;
}

.dropzone.is-active {
  border-color: #c780ff;
  color: #8f00ff;
}

/* Loading */
.loading {
  position: absolute;
  top: 20px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.5em;
  color: #a5a5a5;
}

/* Heatmap */
.react-calendar-heatmap text {
  font-size: 10px;
  fill: #aaa;
}

.react-calendar-heatmap .react-calendar-heatmap-small-text {
  font-size: 5px;
}

.react-calendar-heatmap rect:hover {
  stroke: #555;
  stroke-width: 1px;
}

/*
 * Default color scale
 */

.react-calendar-heatmap .color-empty {
  fill: #eee;
}

.react-calendar-heatmap .color-filled {
  fill: rgba(143, 0, 255, 1);
}

/*
 * Explorata color scale
 */

.react-calendar-heatmap .color-explorata-0 {
  fill: rgba(143, 0, 255, 0);
}
.react-calendar-heatmap .color-explorata-1 {
  fill: rgba(143, 0, 255, 0.1);
}
.react-calendar-heatmap .color-explorata-2 {
  fill: rgba(143, 0, 255, 0.2);
}
.react-calendar-heatmap .color-explorata-3 {
  fill: rgba(143, 0, 255, 0.3);
}
.react-calendar-heatmap .color-explorata-4 {
  fill: rgba(143, 0, 255, 0.4);
}
.react-calendar-heatmap .color-explorata-5 {
  fill: rgba(143, 0, 255, 0.5);
}
.react-calendar-heatmap .color-explorata-6 {
  fill: rgba(143, 0, 255, 0.6);
}
.react-calendar-heatmap .color-explorata-7 {
  fill: rgba(143, 0, 255, 0.7);
}
.react-calendar-heatmap .color-explorata-8 {
  fill: rgba(143, 0, 255, 0.8);
}
.react-calendar-heatmap .color-explorata-9 {
  fill: rgba(143, 0, 255, 0.9);
}
.react-calendar-heatmap .color-explorata-10 {
  fill: rgba(143, 0, 255, 1);
}

.discussion > .chatrow {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-flow: column wrap;
}

.discussion > .chatrow > .bubble {
  border-radius: 1em;
  padding: 0.25em 0.75em;
  margin: 0.0625em;
  max-width: 50%;
}

.discussion > .chatrow > .time {
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  padding: 5px 0;
}

.discussion > .chatrow > .bubble.sender {
  align-self: flex-start;
  background-color: #8f00ff;
  color: #fff;
}
.discussion > .chatrow > .bubble.recipient {
  align-self: flex-end;
  background-color: #efefef;
}

.discussion > .chatrow > .bubble.sender.first {
  border-bottom-left-radius: 0.1em;
}
.discussion > .chatrow > .bubble.sender.last {
  border-top-left-radius: 0.1em;
}
.discussion > .chatrow > .bubble.sender.middle {
  border-bottom-left-radius: 0.1em;
  border-top-left-radius: 0.1em;
}

.discussion > .chatrow > .bubble.recipient.first {
  border-bottom-right-radius: 0.1em;
}
.discussion > .chatrow > .bubble.recipient.last {
  border-top-right-radius: 0.1em;
}
.discussion > .chatrow > .bubble.recipient.middle {
  border-bottom-right-radius: 0.1em;
  border-top-right-radius: 0.1em;
}
